import { useMutation } from "@tanstack/vue-query";

const sendHowDidYouFindUs = async (variables) => {
  return await fetchWithAuth("/api/currentUser/howDidYouFindUs", {
    method: "POST",
    body: variables,
  });
};

export const useHowDidYouFindUsMutation = () => {
  return useMutation({
    mutationFn: sendHowDidYouFindUs,
  });
};
